<template>
  <!--   <pre><code>{{ JSON.stringify(notation, null, 2) }}</code></pre> -->
  <div>
    <form @submit.prevent="onSubmit()">
      <Section>
        <Container>
          <h2 class="section-title">Définition</h2>
          <Radio
            id="variablepersonnalisee"
            :items="[
                      { label: 'Prédéfinie', value: false },
                      { label: 'Personalisée', value: true },
                    ]"
            inputStyle="inline"
            label="Mode"
            @update:modelValue="changeDef($event)"
          />
          <!-- Prédéfinie -->
          <SelectExtended
            v-show="!isUndefined"
            id="variable_uid"
            label="Variable"
            option-key="uid"
            option-value="designation"
            api-endpoint="variable?sort=designation.ASC"
            class="max-width-sm"
          />
          <!-- Personnalisé -->
          <SelectExtended
            v-show="isUndefined"
            id="type_uid"
            label="Type de variable"
            option-key="uid"
            option-value="designation"
            api-endpoint="dictionnaire/type/variable"
            @update:modelValue="changeType($event)"
            class="max-width-sm"
          />
          <Input
            v-show="isUndefined"
            id="designation"
            label="Désignation"
          />
          <Radio
            v-show="isUndefined"
            id="laboratoire"
            :items="[
              { label: 'En laboratoire', value: true },
              { label: 'Sur le terrain', value: false },
            ]"
            label="Localisation"
            inputStyle="inline"
          />
          <!-- Commun -->
          <SelectExtended
            id="cible_id"
            label="Cible"
            option-key="id"
            option-value="valeur"
            :api-endpoint="`dictionnaire/referentiel/${ruid}/valeur?sort=valeur.ASC`"
            class="max-width-sm"
          />
        </Container>
      </Section>
      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn
            :to="{
              name: 'protocolExperimentalTaskVariableStudiedListEdit',
              params: {
                id: $route.params.id,
                tid: $route.params.tid
              }
            }"
            text="Annuler"
          />
          <Btn
            v-if="$route.name.includes('Edit')"
            btnType="submit" text="Enregistrer" color="primary"
          />
          <Btn v-else btnType="submit" text="Suivant" color="primary" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Radio from '@/components/form/Radio.vue'
import Input from '@/components/form/Input.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import FormService from '@/services/form.service'

export default {
  name: 'VariablesStudiedSettingsSubview',
  components: {
    Radio,
    Input,
    SelectExtended,
    Btn,
    Container,
    Section,
  },
  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    variableProps: {
      type: Object,
    },
    notation: {
      type: Object,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data(props) {
    const FS = new FormService()
    FS.setEmitter(this.emitter)

    const validationSchema = this.yup.object().shape({
      variablepersonnalisee: this.yup.boolean().nullable(),
      variable_uid: this.yup.object().nullable(),
      designation: this.yup.string().nullable(),
      type_uid: this.yup.object().nullable(),
      laboratoire: this.yup.boolean().nullable(),
      cible_id: this.yup.object().nullable(),
    })

    const { errors, isSubmitting } = FS.initFrom(
      validationSchema,
    )
    const onSubmit = FS.handleSubmit((values) => {
      this.handleSubmit(values)
    })

    return {
      FS,
      ruid: this.helperService.getReferentialByUid('cible'),
      method: 'post',
      errors,
      isSubmitting,
      onSubmit,
      variable: props.variableProps,
      schema: null,
      vId: this.$route.params.veid ?? null,
    }
  },

  computed: {
    isUndefined() {
      if (typeof this.variable.isUndefined === 'string') {
        return this.variable.isUndefined === 'true'
      }
      return this.variable.isUndefined
    },
  },
  mounted() {
    if (!this.variable.id) {
      this.changeDef(false)
      this.variable.variablepersonnalisee = this.variable.isUndefined
    } else {
      this.method = 'put'
      this.changeDef(this.variable.variablepersonnalisee)
    }
    this.schema = this.FS.populateShema(this.variable)
    if (this.variable.id) {
      this.schema.type_uid = {
        key: this.variable?.type?.uid,
        value: this.variable?.type?.designation,
      }
      this.schema.cible_id = {
        key: this.variable.cible?.id,
        value: this.variable.cible?.valeur,
      }
      this.schema.variable_uid = this.variable?.variable?.uid ? {
        key: this.variable?.variable?.uid,
        value: this.variable?.variable?.designation,
      } : null
    }
    this.FS.setFormValues(this.schema)
  },
  methods: {
    changeType(event) {
      this.type = event
    },
    changeDef(event) {
      this.variable.isUndefined = event
    },
    getValeursToUpdate(items = []) {
      return items.filter((item) => 'id' in item)
    },
    getValeursToCreate(items = []) {
      return items.filter((item) => item.id === undefined)
    },
    getValeursToDelete(items = []) {
      if (this.variable?.valeurs) {
        return this.variable.valeurs.filter((item) => {
          const ids = items.map((i) => i.id)
          return !ids.includes(item.id)
        })
      }

      return []
    },
    handleSubmit(values) {
      const params = { ...values }
      if (this.isUndefined === false) {
        if (!params.designation) {
          params.designation = values.variable_uid?.value
        }
        params.valeurs = undefined
        params.type_uid = null
        params.laboratoire = false
        params.variable_uid = values?.variable_uid?.key
        params.designation = values.variable_uid?.value
      } else {
        params.variable_uid = null
        params.type_uid = values?.type_uid?.key
      }
      params.cible_id = values.cible_id?.key
      params.saisie_niveau_uid = 'MICROPARCELLE'

      const routeParams = this.$route.params
      let endpoint = `protocole/${routeParams.id}/evenement/${routeParams.tid}/variable`
      if (this.variable.id) {
        endpoint = `protocole/${routeParams.id}/evenement/${routeParams.tid}/variable/${routeParams.veid}`
      }

      this.fetchService[this.method](endpoint, params).then((response) => {
        if (routeParams.veid) {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'La variable a bien été modifiée.',
          })
        } else {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'La variable a bien été créée.',
          })
        }
        this.$router.push({
          name: `protocolExperimentalTaskVariableStudiedEntryLevel${this.$route.name.includes('Edit') ? 'Edit' : 'Add'}`,
          params: {
            id: this.$route.params.id,
            tid: this.$route.params.tid,
            veid: response.data.id,
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
