<template>
  <form @submit.prevent="onSubmit()">
    <Section>
      <Container>
        <h2 class="section-title">Niveau de saisie</h2>
        <Radio v-if="saisie_levels" id="saisie_niveau_uid" :items="saisie_levels"
          @update:modelValue="toggleIsOrgane($event)" />

        <SelectExtended v-show="isOrgane" id="organe" label="Organe" :apiParams="{ sort: 'valeur.ASC' }" optionKey="id"
          optionValue="valeur" :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
            'organe'
          )}/valeur`" />

        <Input v-show="isOrgane" id="nb_echantillons" label="Nombre d'échantillons" type="number" :options="{min:1}" />

      </Container>
    </Section>
    <div class="view-footer">
      <div class="view-actions view-actions--left">
        <Btn :to="{
          name: 'protocolExperimentalTaskVariableStudiedListEdit',
          params: { id: $route.params.id, tid: $route.params.tid }
        }" text="Annuler" />
        <Btn v-if="$route.name.includes('Edit')" btnType="submit" text="Enregistrer" color="primary" />
        <Btn v-else btnType="submit" text="Enregistrer" color="primary" />
      </div>
    </div>
  </form>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Radio from '@/components/form/Radio.vue'
import Input from '@/components/form/Input.vue'
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import FormService from '@/services/form.service'
import SelectExtended from '@/components/form/SelectExtended.vue'

export default {
  name: 'VariablesStudiedEntryLevelSubview',
  components: {
    Radio,
    Input,
    Btn,
    Container,
    SelectExtended,
    Section,
  },
  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    variableProps: {
      type: Object,
    },
    notation: {
      type: Object,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data(props) {
    const FS = new FormService()
    FS.setEmitter(this.emitter)
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      saisie_niveau_uid: this.yup.object().nullable(),
      organe: this.yup.object().nullable(),
      nb_echantillons: this.yup.number().min(1).nullable(),
    })

    const { errors, isSubmitting } = FS.initFrom(
      validationSchema,
    )
    const onSubmit = FS.handleSubmit((values) => {
      this.handleSubmit(values)
    })

    return {
      FS,
      organesDraggables: this.$route.name.includes('Edit'),
      saisie_levels: [],
      method: 'put',
      errors,
      isSubmitting,
      onSubmit,
      variable: props.variableProps,
      organes: [],
      schema: {},
    }
  },

  computed: {
    isOrgane() {
      return this.variable.isOrgane
    },
  },

  mounted() {
    this.getLevels()
    if (this.$route.name.includes('Add')) {
      this.variable.isOrgane = false
    }
    this.schema = this.FS.populateShema(this.variable)

    if (this.variable.id) {
      this.variable.isOrgane = this.variable?.saisie_niveau?.uid === 'ORGANE'
      this.schema.saisie_niveau_uid = this.variable.saisie_niveau?.uid
    }

    this.FS.setFormValues(this.schema)
  },

  methods: {
    getLevels() {
      this.fetchService.get('dictionnaire/saisie/niveau').then(
        (res) => {
          const datas = res.data
          if (datas.length > 0) {
            this.saisie_levels = datas.map((data) => ({
              label: data.designation,
              value: data.uid,
              id: data.id,
            }))
            this.saisie_levels.sort((a, b) => a.id - b.id)
          }
        },
      )
      return this.saisie_levels
    },

    toggleIsOrgane(event) {
      this.variable.isOrgane = event === 'ORGANE'
    },

    handleSubmit(values) {
      const { variable } = this
      const params = { ...values, ...variable }
      if (['MODALITE', 'MICROPARCELLE'].includes(params.saisie_niveau_uid)) {
        params.organes = []
      } else {
        // eslint-disable-next-line no-param-reassign
        params.organes = [
          {
            organe_id: values?.organe?.key,
            nb_echantillons: values?.nb_echantillons,
            designation: values?.organe?.value,
            ordre: 1,
          },
        ]
      }
      params.laboratoire = variable.laboratoire ?? false

      this.variable.organes = params.organes
      const rp = this.$route.params
      const endpoint = `protocole/${this.$route.params.id}/evenement/${this.$route.params.tid}/variable/${rp.veid}`

      this.fetchService.put(endpoint, params)
        .then((result) => {
          const veid = result.data.id
          const { success } = result.meta
          if (success) {
            if (params.organes.length > 0) {
              if (this.$route.name.includes('Add')) {
                params.organes.forEach((organe) => {
                  this.fetchService.post(`${endpoint}/organe`, organe).then((ro) => {
                    const { id } = ro.data
                    console.log(`Organe ${id} créé`)
                  })
                })
              } else {
                if (this.variable?.organe) {
                  this.fetchService.delete(`${endpoint}/organe/${this.variable?.organe.key}`).then(() => {
                    console.log('Organe supprimé')
                  })
                }
                params.organes.forEach((valeur) => {
                  this.fetchService.post(`${endpoint}/organe`, valeur).then(() => {
                    console.log('Organe créé')
                  })
                })
              }
            } else if (this.variable?.organe) {
              this.fetchService.delete(`${endpoint}/organe/${this.variable?.organe.id}`).then(() => {
                console.log('Organe supprimé')
              })
            }

            if (this.$route.name.includes('Edit')) {
              this.emitter.emit('alert', {
                type: 'success',
                content: 'Le niveau de saisie de la variable étudiée a bien été modifié.',
              })
              this.$router.push({
                name: 'protocolExperimentalTaskVariableStudiedEntryLevel',
                params: {
                  id: this.$route.params.id,
                  tid: this.$route.params.tid,
                  veid,
                },
              })
            } else {
              this.emitter.emit('alert', {
                type: 'success',
                content: 'Le niveau de saisie de la variable étudiée a bien été créé.',
              })

              this.$router.push({
                name: 'protocolExperimentalTaskVariableStudiedListEdit',
                params: {
                  id: this.$route.params.id,
                  tid: this.$route.params.tid,
                },
              })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
